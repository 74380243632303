import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styles from "./how.module.css";

export default function How() {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              heading
              stages {
                number
                heading
                text
              }
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { heading, stages } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "how";
  }).node;

  return (
    <>
      <div className={styles.heading}><h3 dangerouslySetInnerHTML={{__html: heading}} /></div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4><span className={styles.number}>{stages[0].number}.</span><span dangerouslySetInnerHTML={{__html: stages[0].heading}}/></h4>
          <div className={styles.text} dangerouslySetInnerHTML={{__html: stages[0].text}}/>
        </div>
        <div className={`${styles.image} ${styles.image1} ${styles.imageRight}`} />
      </div>
      <div className={`${styles.wrapper} ${styles.wrapperLeft}`}>
        <div className={styles.content}>
          <h4><span className={styles.number}>{stages[1].number}.</span><span dangerouslySetInnerHTML={{__html: stages[0].heading}}/></h4>
          <div className={styles.text} dangerouslySetInnerHTML={{__html: stages[1].text}}/>
        </div>
        <div className={`${styles.image} ${styles.image2} ${styles.imageLeft}`} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4><span className={styles.number}>{stages[2].number}.</span><span dangerouslySetInnerHTML={{__html: stages[2].heading}}/></h4>
          <div className={styles.text} dangerouslySetInnerHTML={{__html: stages[2].text}}/>
        </div>
        <div className={`${styles.image} ${styles.image3} ${styles.imageRight}`} />
      </div>
      <div className={`${styles.wrapper} ${styles.wrapperLeft}`}>
        <div className={styles.content}>
          <h4><span className={styles.number}>{stages[3].number}.</span><span dangerouslySetInnerHTML={{__html: stages[3].heading}}/></h4>
          <div className={styles.text} dangerouslySetInnerHTML={{__html: stages[3].text}}/>
        </div>
        <div className={`${styles.image} ${styles.image4} ${styles.imageLeft}`} />
      </div>
    </>
  );
}
