import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import termsPdf from "../downloads/Regulamin-konkursu-Lider-Usług-Publicznych-2021.pdf"; 
import formPdf from "../downloads/Formularz-Zgłoszeniowy.pdf"; 
import styles from "./contact.module.css";

const Contact = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              heading
              form {
                heading
              }
              email {
                heading
                address
              }
              phone {
                heading
                number
              }
              terms {
                heading
              }
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { heading, form, email, phone, terms } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "contact";
  }).node;

  return (
    <div className={styles.wrapper}>
      <h3>{heading}</h3>
      <div className={styles.field}>
        <h4>{form.heading}</h4>
        <a href={formPdf} download>Pobierz formularz zgłoszenia</a>{` `}
      </div>
      <div className={styles.field}>
        <h4>{email.heading}</h4>
        <a href={`mailto:${email.address}`}>{email.address}</a>
      </div>
      <div className={styles.field}>
        <h4>{phone.heading}</h4>
        <span>{phone.number}</span>
      </div>
      <div className={styles.field}>
        <h4>{terms.heading}</h4>
        <a href={termsPdf} download>Pobierz regulamin</a>{` `}
      </div>
    </div>
  )
};

export default Contact;
