import React from "react";
import Intro from "./intro";
import styles from "./why.module.css";

export default function Why() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.image} />
      <Intro sectionName="why" />
    </div>
  );
}
