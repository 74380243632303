import React from "react";
import CtoButton from "./cto_button";
import { useStaticQuery, graphql } from "gatsby";
import logo from "./logo_large.svg";
import styles from "./what.module.css";

export default function What() {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              heading
              text
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { heading, text } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "what";
  }).node;

  return (
    <>
      <div className={styles.heading}><h3 dangerouslySetInnerHTML={{__html: heading}} /></div>
      <div className={styles.wrapper}>
        <div className={`${styles.tile} ${styles.left}`}>
          <div className={styles.participant} dangerouslySetInnerHTML={{__html: text}} />
          <div className={styles.ctoWrapper}>
            <CtoButton color="white" />
          </div>
        </div>
        <div className={`${styles.tile} ${styles.right}`}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </div>
      </div>
    </>
  );
}
