import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import introImage from "./intro_side.png";
import Masthead from "./masthead";
import Contact from "./contact";
import Intro from "./intro";
import Why from "./why";
import ForWhom from "./forWhom";
import How from "./how";
import What from "./what";
import Navigation from "./navigation";
import styles from "./homepageContainer.module.css";

export default function HomepageContainer() {
  const data = useStaticQuery(
    graphql`
      query {
        allPagesJson {
          edges {
            node {
              masthead {
                title
                subtitle
                text
              }
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const {masthead} = data.allPagesJson.edges.find((edge) => {
    return edge.node.parent.name === "homepage";
  }).node;

  return (
    <div>
      <Masthead title={masthead.title} subtitle={masthead.subtitle} text={masthead.text} />
      <Navigation />
      <main>
        <section id="poczatek">
          <Intro sectionName="intro" image={introImage} />
        </section>
        <section id="o-plebiscycie">
          <Intro sectionName="about" />
        </section>
        <section id="dlaczego-warto">
          <Why />
        </section>
        <section id="dla-kogo">
          <ForWhom/>
        </section>
        <section id="przebieg-plebiscytu">
          <How/>
        </section>
        <section id="pakiet-konkursowy">
          <What/>
        </section>
        <section id="kontakt">
          <Contact />
        </section>
      </main>
    </div>
  );
}
