import React from "react";
import CtoButton from "./cto_button";
import styles from "./masthead.module.css"

export default function Masthead({title, subtitle, text}) {
  return (
    <div className={styles.masthead}>
      <div className={styles.content}>
        <h2 dangerouslySetInnerHTML={{__html: subtitle}} />
        <h1 dangerouslySetInnerHTML={{__html: title}} />
        <div dangerouslySetInnerHTML={{__html: text}} />
        <div className={styles.ctoWrapper}>
          <CtoButton color="white" />
        </div>
      </div>
    </div>
  );
}
