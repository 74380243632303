import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CtoButton from "./cto_button";
import styles from "./intro.module.css";

export default function Intro({image, sectionName}) {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              heading
              text
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  //TODO : extract both query and transformation below to a common fetch function
  const { heading, text } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === sectionName;
  }).node;

  return (
    <div>
      <div className={styles.content}>
        <div>
          <h3 dangerouslySetInnerHTML={{__html: heading}} />
          <div className={styles.text} dangerouslySetInnerHTML={{__html: text}} />
        </div>
        {image && (
          <img className={styles.image} src={image} alt="Intro" />
        )}
      </div>
      <div className={styles.ctoWrapper}>
        <CtoButton/>
        <div className={styles.separator}/>
      </div>
    </div>
  );
}
