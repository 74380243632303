import React from "react";
import Button from "./button";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function CtoButton({color}) {
  return (
    <AnchorLink to="/#kontakt" ><Button color={color} text="Weź udział w plebiscycie" isCto /></AnchorLink>
  );
}

