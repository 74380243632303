import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styles from "./navigation.module.css";
import Sticky from 'react-stickynode';
 
export default function Navigation() {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              rights
              author
              links {
                url
                text
              }
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const links = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "navigation"
  }).node.links;

  return (
    <Sticky enabled={true} top={250}>
      <nav className={styles.navigation}>
        <ul className={styles.navigationList}>
          {links.map((link) => (
            <li><h4><AnchorLink to={link.url} title={link.text} /></h4></li>
          ))}
        </ul>
      </nav>
    </Sticky>
  );
}
