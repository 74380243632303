import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CtoButton from "./cto_button";
import participant1 from "./participant_1.png";
import participant2 from "./participant_2.png";
import participant3 from "./participant_3.png";
import styles from "./forWhom.module.css";

export default function ForWhom() {
  const data = useStaticQuery(
    graphql`
      query {
        allComponentsJson {
          edges {
            node {
              heading
              participants {
                text
              }
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { heading, participants } = data.allComponentsJson.edges.find((edge) => {
    return edge.node.parent.name === "forwhom";
  }).node;

  return (
    <>
      <div className={styles.heading}><h3 dangerouslySetInnerHTML={{__html: heading}} /></div>
      <div className={styles.wrapper}>
        <div className={`${styles.participantWrapper} ${styles.first}`}>
          <img className={styles.logo} src={participant1} alt="Logo" />
          <div className={styles.participant} dangerouslySetInnerHTML={{__html: participants[0].text}} />
        </div>
        <div className={styles.participantWrapper}>
          <img className={styles.logo} src={participant2} alt="Logo" />
          <div className={styles.participant} dangerouslySetInnerHTML={{__html: participants[1].text}} />
        </div>
        <div className={`${styles.participantWrapper} ${styles.last}`}>
          <img className={styles.logo} src={participant3} alt="Logo" />
          <div className={styles.participant} dangerouslySetInnerHTML={{__html: participants[2].text}} />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <CtoButton color="green" />
      </div>
    </>
  );
}
